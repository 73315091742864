body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: azure;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div,
main,
section {
  margin: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}
.up-link {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: salmon;
  padding: 8px;
  color: white;
  font-size: 18px;
  box-shadow: 0 0 4px black;
}
.up-link:hover {
  background-color: #8b4513d1;
}

#staff {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
}
.staff-top,
.staff-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 960px;
}
.staff {
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.staff img {
  width: 200px;
}
.info {
  min-width: 210px;
  max-width: 240px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

@media screen and (max-width: 480px) {
  .info {
    text-align: center;
    padding: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin: 4px;
  }
}
.icon {
  color: black;
  transition: 0.5s linear;
  font-size: 21px;
  margin-right: 8px;
}
.icon:hover {
  transform: rotate(360deg);
  color: salmon;
}

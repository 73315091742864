#gallery {
  display: flex;
  flex-direction: column;
}
#gallery h1 {
  margin: 16px auto;
}
.gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-width: 820px;
  margin: auto;
  width: 80%;
  grid-gap: 1px;
}
.gallery__item {
  margin: 0;
}
.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.parent {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 3;
}
.div2 {
  grid-area: 2 / 1 / 4 / 2;
}
.div3 {
  grid-area: 3 / 4 / 4 / 6;
}
.div4 {
  grid-area: 1 / 3 / 2 / 6;
}
.div5 {
  grid-area: 2 / 2 / 4 / 4;
}
.div6 {
  grid-area: 4 / 1 / 5 / 4;
}
.div7 {
  grid-area: 2 / 4 / 3 / 6;
}
.div8 {
  grid-area: 4 / 4 / 5 / 6;
}
#gallery img {
  height: 100%;
}

@media screen and (max-width: 480px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .div1 {
    grid-area: 1 / 1 / 3 / 3;
  }
  .div2 {
    grid-area: 1 / 3 / 5 / 4;
  }
  .div3 {
    grid-area: 3 / 2 / 5 / 3;
  }
  .div4 {
    grid-area: 7 / 1 / 9 / 3;
  }
  .div5 {
    grid-area: 5 / 2 / 7 / 4;
  }
  .div6 {
    grid-area: 7 / 3 / 9 / 4;
  }
  .div7 {
    grid-area: 3 / 1 / 6 / 2;
  }
  .div8 {
    grid-area: 6 / 1 / 7 / 2;
  }
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: max(320px, min(100vh, 480px));
}
#about article {
  height: max(320px, min(100vh, 480px));
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
#about > article div {
  border: 1px solid;
  border-radius: 4px;
}
